import { configureStore, combineReducers, AnyAction } from "@reduxjs/toolkit";

import dealsSlice from "features/deals/store/slice";
import requestSlice from "application/store/request/slice";
import { dealPageSlice } from "features/deal-profile/store/slice";
import authSlice from "./Auth/AuthSlice";
import { ResetStateActionType } from "application/constants/store";
import usersSlice from "features/users/store/slice";
import { portfolioSlice } from "features/portfolio/store/slice";
import { investorsSlice } from "features/portfolio-investors/store/slice";
import { managementAnalyticsSlice } from "features/management-analytics/store/slice";
import { dealInvestorsSlice } from "features/deal-investors/store/slice";
import { portfolioInvestorSlice } from "features/portfolio-investor-detail/store/slice";
import detectChangesSavedSlice from "./DetectChangesSaved/DetectChangesSavedSlice";
import { lostReasonsSlice } from "application/containers/LostDealPopUp/store/slice";
import countrySlice from "./Country/CountrySlice";
import pipedriveSlice from "./Pipedrive/PipedriveSlice";
import ownersSlice from "features/owners/store/slice";
import referralsSlice from "features/referrals/store/slice";
import companyListSlice from "features/companies/store/slice";
import errorSlice from "./Error/ErrorSlice";

const reducer = combineReducers({
  deals: dealsSlice,
  dealPage: dealPageSlice.reducer,
  request: requestSlice,
  auth: authSlice,
  users: usersSlice,
  portfolio: portfolioSlice.reducer,
  investors: investorsSlice.reducer,
  managementAnalytics: managementAnalyticsSlice.reducer,
  dealInvestors: dealInvestorsSlice.reducer,
  portfolioInvestor: portfolioInvestorSlice.reducer,
  detectChangesSavedSlice: detectChangesSavedSlice,
  lostReasons: lostReasonsSlice.reducer,
  countries: countrySlice,
  owners: ownersSlice,
  pipedrive: pipedriveSlice,
  referrals: referralsSlice,
  companyList: companyListSlice,
  error: errorSlice,
});

const rootReducer: typeof reducer = (
  state: RootState | undefined,
  action: AnyAction
) => {
  if (action.type === ResetStateActionType) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
