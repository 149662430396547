import { PathNames } from "application/routes/constants";

import { ReactComponent as HomeSvg } from "features/side-menu/assets/home.svg";
import { ReactComponent as HomeActiveSvg } from "features/side-menu/assets/home-active.svg";

import { ReactComponent as UserSvg } from "features/side-menu/assets/user.svg";
import { ReactComponent as UserActiveSvg } from "features/side-menu/assets/user-active.svg";

import { ReactComponent as PortfolioSvg } from "features/side-menu/assets/portfolio.svg";
import { ReactComponent as PortfolioActiveSvg } from "features/side-menu/assets/portfolio-active.svg";

import { ReactComponent as OwnersSvg } from "features/side-menu/assets/owners.svg";
import { ReactComponent as ReferralsActiveSvg } from "features/side-menu/assets/referrals-active.svg";

import { ReactComponent as ReferralsSvg } from "features/side-menu/assets/referrals.svg";
import { ReactComponent as OwnersActiveSvg } from "features/side-menu/assets/owners-active.svg";

import { ReactComponent as CompaniesSvg } from "features/side-menu/assets/companies.svg";
import { ReactComponent as CompaniesActiveSvg } from "features/side-menu/assets/companies-active.svg";

export interface MenuListItem {
  title: string;
  path: PathNames;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  iconActive: React.FC<React.SVGProps<SVGSVGElement>>;
}

export const menuList: MenuListItem[] = [
  {
    title: "Deals",
    path: PathNames.deals,
    icon: HomeSvg,
    iconActive: HomeActiveSvg,
  },
  {
    title: "Users",
    path: PathNames.users,
    icon: UserSvg,
    iconActive: UserActiveSvg,
  },
  {
    title: "Portfolio",
    path: PathNames.portfolio,
    icon: PortfolioSvg,
    iconActive: PortfolioActiveSvg,
  },
  {
    title: "Referrals",
    path: PathNames.referrals,
    icon: ReferralsSvg,
    iconActive: ReferralsActiveSvg,
  },
  {
    title: "Owners",
    path: PathNames.owners,
    icon: OwnersSvg,
    iconActive: OwnersActiveSvg,
  },
  {
    title: "Companies",
    path: PathNames.companies,
    icon: CompaniesSvg,
    iconActive: CompaniesActiveSvg,
  },
];
