import React, { useState } from "react";

import { useAppDispatch } from "application/hooks/useAppDispatch";
import { logOut } from "application/store/Auth/ActionCreators";

import { menuList } from "./config/menu-list";

import MenuItem from "./components/MenuItem";
import UserInfo from "./components/UserInfo";

import {
  Header,
  Footer,
  Wrapper,
  ArrowIn,
  ArrowOut,
  LogoImage,
  LogoutIcon,
  LogoSmallImage,
  ArrowContainer,
  LogoutButton,
} from "./styles";

const SideMenu = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const onLogOut = async () => {
    await dispatch(logOut());
  };

  return (
    <Wrapper open={open}>
      <Header open={open}>
        {open ? <LogoImage /> : <LogoSmallImage />}
        <ArrowContainer onClick={toggleOpen}>
          {open ? <ArrowIn /> : <ArrowOut />}
        </ArrowContainer>
      </Header>

      <UserInfo open={open} />

      {menuList.map((item) => (
        <MenuItem key={item.path} open={open} item={item} />
      ))}

      <Footer open={open}>
        <LogoutButton type="button" onClick={onLogOut}>
          {open && <p>Log Out</p>}
          <LogoutIcon />
        </LogoutButton>
      </Footer>
    </Wrapper>
  );
};

export default SideMenu;
