export enum PathNames {
  error = "/error",
  login = "/login",
  loginVerification = "/login/code-verification",
  forgotPassword = "/forgot-password",
  resetPassword = "/reset-password",
  resentPassword = "/resent-password",
  confirmPassword = "/admin/sign-in/forgot-password",
  createPassword = "/create-new-password",
  //
  deals = "/deals",
  users = "/users",
  portfolio = "/portfolio",
  referrals = "/referrals",
  owners = "/owners",
  companies = "/companies",
}

export enum SearchParamsRoutesNames {
  // deals
  deals = "deals",
  deal = "deal",
  commitments = "commitments",
  //portfolio
  portfolio = "portfolio",
  portfolioInvestors = "portfolioInvestors",
  portfolioInvestorsDetailCommitments = "portfolioInvestorsDetailCommitments",
  portfolioInvestorsManagementAnalytics = "portfolioInvestorsManagementAnalytics",
  //companies
  companies = "companies",
  company = "company",
  companyDeals = "companyDeals",
  companyInvestors = "companyInvestors",
  companyManagementAnalytics = "companyManagementAnalytics",
  //users
  users = "/users",
  userDetails = "/userDetails",
}
